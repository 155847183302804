import React from 'react';

import { useIntl } from 'context/IntlContext';

import {
  Button,
  Text,
  Box,
  Image,
  Grid,
  Container,
} from 'components';

import Link from 'components/Link';
import Motto from '../../../../../components/Motto';
import Section from '../../../../../components/Section';

import fondos from './icons/fondos.jpg';

import Time from '../Mortgages/icons/time.svg';
import Seo from '../../../../../components/SEO';

import LineChartIcon from '../../../../../images/icons/lineChart.svg';
import ProtectionIcon from '../../../../../images/icons/protection.svg';
import BagIcon from '../../../../../images/icons/bag.svg';
import DolarIcon from '../../../../../images/icons/cash.svg';


const Fondos = () => {
  const { t } = useIntl();

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Box sx={{ bg: 'primaryBackground', pb: [11, 11, 11, 0] }}>
        <Container sx={{ px: [0, 0, 0, 0] }}>
          <Section sx={{ px: [0, 0, 0, 0] }}>
            <Grid
              columns={['1fr', '1fr', '1fr', '1fr 1fr']}
              sx={{
                columnGap: '0px',
                textAlign: 'initial',
              }}
            >
              <Box
                sx={{
                  display: 'block',
                  gridColumn: ['1', '1', '1', '2'],
                  mb: [11, 11, 11, 0],
                  gridRow: ['2', '2', '2', '1/4'],
                  pr: '20px',
                }}
              >
                <Image
                  src={fondos}
                  alt="Fondos"
                  sx={{
                    display: 'block',
                    width: '533px',
                    height: '393px',
                    margin: '0 0 0 80px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
              <Box
                sx={{
                  gridColumn: '1',
                  gridRow: '1',
                }}
              >
                <Text mb={5} variant="sectionTitle">
                  {t('homepage.marketplace.broker.hero.title')}
                </Text>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    variant="contentTitle"
                    sx={{
                      fontWeight: 'normal',
                      opacity: '0.8',
                    }}
                  >
                    {t('homepage.marketplace.broker.hero.subtitle')}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  as={Link}
                  to={t('homepage.marketplace.broker.cta.link')}
                  variant="primary"
                  sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
                >
                  {t('homepage.marketplace.broker_header.button')}
                </Button>
                <Text
                  sx={{
                    color: '#233143',
                    display: 'block',
                    fontSize: '12px',
                    lineHeight: '24px',
                    marginTop: '16px',
                    textAlign: 'left',
                  }}>
                  {t('marketplace.provider.cta.warning')}
                </Text>
              </Box>
            </Grid>
          </Section>
          <Section noDivider>
            <Box>
              <Text as="h2" variant="sectionTitle" sx={{ textAlign: 'center' }}>
                {t('homepage.marketplace.broker.options_section.title')}
              </Text>
              <Text variant="underHero" sx={{ textAlign: 'center', pt: 7, pb: 7 }}>
                {t('homepage.marketplace.broker.options_section.subtitle')}
              </Text>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  as={Link}
                  to={t('homepage.marketplace.broker.button.open_account.link')}
                  variant="primary"
                  sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
                >
                  {t('homepage.marketplace.broker.button.open_account')}
                </Button>
                <Text
                  sx={{
                    color: '#233143',
                    display: 'block',
                    fontSize: '12px',
                    lineHeight: '24px',
                    marginTop: '16px',
                    textAlign: 'left',
                  }}>
                  {t('marketplace.provider.cta.warning')}
                </Text>
              </Box>
              <Grid
                columns={['1fr', '1fr', '1fr', '1fr 1fr']}
                sx={{
                  columnGap: '160px',
                  textAlign: 'initial',
                  py: 8,
                }}
              >
                <Motto
                  title={t(
                    'homepage.marketplace.broker.options_section.operaciones_con_apalancamiento',
                  )}
                  description={t(
                    'homepage.marketplace.broker.options_section.operaciones_con_apalancamiento.detail',
                  )}
                  icon={<LineChartIcon />}
                />
                <Motto
                  title={t(
                    'homepage.marketplace.broker.options_section.fiabilidad',
                  )}
                  description={t(
                    'homepage.marketplace.broker.options_section.fiabilidad.detail',
                  )}
                  icon={<ProtectionIcon />}
                />
                <Motto
                  title={t(
                    'homepage.marketplace.broker.options_section.regulado',
                  )}
                  description={t(
                    'homepage.marketplace.broker.options_section.regulado.details',
                  )}
                  icon={<Time />}
                />
                <Motto
                  title={t(
                    'homepage.marketplace.broker.options_section.cotizaciones',
                  )}
                  description={t(
                    'homepage.marketplace.broker.options_section.cotizaciones.detail',
                  )}
                  icon={<BagIcon />}
                />
                <Box
                  sx={{
                    flexGrow: 1,
                    flexBasis: 0,
                    width: 'auto',
                    py: 7,
                    px: [0, 0, 2],
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      as={Link}
                      to={t('homepage.marketplace.broker.button.cliente_access.link')}
                      variant="primary"
                      sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
                    >
                      {t('homepage.marketplace.broker.button.cliente_access')}
                    </Button>
                  </Box>
                  <Text
                    sx={{
                      color: '#233143',
                      display: 'block',
                      fontSize: '12px',
                      lineHeight: '24px',
                      marginTop: '16px',
                      textAlign: 'left',
                    }}>
                    {t('marketplace.provider.cta.warning')}
                  </Text>
                </Box>
                <Motto
                  title={t(
                    'homepage.marketplace.broker.options_section.comisiones_bajas',
                  )}
                  description={t(
                    'homepage.marketplace.broker.options_section.comisiones_bajas.detail',
                  )}
                  icon={<DolarIcon />}
                />

              </Grid>
              <Box
                sx={{
                  borderRadius: 2,
                  px: [4, 4, 8, 10],
                  pt: 3,
                  pb: 8,
                  bg: 'quaternaryBackground',
                }}
              >
                <Text variant="contentTitle">
                  {t('homepage.marketplace.broker.footer.disclaimer')}
                </Text>
                <Text>
                  {t('homepage.marketplace.broker.footer.disclaimer.risk')}
                </Text>
              </Box>
            </Box>
          </Section>
        </Container>
      </Box>
    </>
  );
};

export default Fondos;
