import React, { FC } from 'react';
import { Box, Text, Flex } from 'components';
import { alpha } from '@theme-ui/color';
import { useIntl } from 'context/IntlContext';
import { TabTitle } from './styled';

interface TableSectionProps {
  accountType: string;
  bgAccountType: string;
  colorAccountType: string;
  firstTab: string;
  secondTab: string;
  minInvestment: string;
}

const TableSection: FC<TableSectionProps> = ({
  accountType,
  bgAccountType,
  colorAccountType,
  firstTab,
  secondTab,
  minInvestment,
}) => {
  const { t } = useIntl();

  return (
    <Box
      sx={{
        bg: 'secondary',
        flexBasis: 200,
        m: 4,
        borderRadius: 10,
      }}
    >
      <Flex
        sx={{
          fontSize: 1,
          justifyContent: 'center',
        }}
      >
        <Box
          backgroundColor={bgAccountType}
          color={colorAccountType}
          sx={{
            borderRadius: '10px 10px 0 0',
            minWidth: '100%',
            fontSize: 1,
            py: 2,
            textAlign: 'center',
          }}
        >
          <TabTitle>{accountType}</TabTitle>
        </Box>
      </Flex>

      <Flex
        sx={{
          borderBottom: '1px solid',
          borderColor: alpha('quaternary', 0.1),
          py: 5,
          fontSize: 3,
          justifyContent: 'center',
        }}
      >
        <Box>
          <TabTitle>{firstTab}</TabTitle>
        </Box>
      </Flex>

      <Flex
        sx={{
          py: 5,
          fontSize: 60,
          justifyContent: 'center',
          color: 'primary',
          fontWeight: 'bold',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <TabTitle>{secondTab}</TabTitle>
          <Text
            sx={{
              color: 'primary',
              fontSize: 7,
              pt: '25px',
            }}
          >
            %
          </Text>
        </Box>
      </Flex>
      <Flex
        sx={{
          borderBottom: '1px solid',
          borderColor: alpha('quaternary', 0.1),
          justifyContent: 'center',
          color: 'primary',
          fontSize: 3,
          fontWeight: 'normal',
        }}
      >
        <Box>
          <TabTitle>{t('global.aer')}</TabTitle>
        </Box>
      </Flex>

      <Flex
        sx={{
          borderBottom: '1px solid',
          borderColor: alpha('quaternary', 0.1),
          color: alpha('quaternary', 0.5),
          py: 5,
          fontSize: 0,
          justifyContent: 'center',
        }}
      >
        <Box>
          <TabTitle>{t('global.min_investment')}</TabTitle>
          <Text
            sx={{
              color: 'quaternary',
              fontSize: 1,
              fontWeight: 'semibold',
              display: 'block'
            }}
          >
            {`€ ${minInvestment}`}
          </Text>
        </Box>
      </Flex>

      <Flex
        sx={{
          borderBottom: '1px solid',
          borderColor: alpha('quaternary', 0.1),
          borderRadius: '0 0 10px 10px',
          color: 'rgba(49, 172, 85, 0.5)',
          py: 5,
          fontSize: 3,
          justifyContent: 'center',
          background: 'rgba(49, 172, 85, 0.1)'
        }}
      >
        <Box
          sx={{
            px: 3,
          }}
        >
          <TabTitle>{t('global.daily_interest')}</TabTitle>
        </Box>
      </Flex>
    </Box>
  );
};

export default TableSection;
