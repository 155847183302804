import styled from '@emotion/styled';

const ChartSVG = styled.svg`
  height: 300px;
  overflow: visible;
  width: 100%;
  @media screen and (max-width: 860px) {
    margin-top: 4rem;
  }
  svg {
  }
`;

const LegendContainer = styled.div`
  display: flex;
  position: absolute;
  @media screen and (max-width: 320px) {
    right: 0;
  }
  @media screen and (max-width: 860px) {
    flex-direction: column;
    width: 300px;
  }
`;

const LegendBlock = styled.div`
  position: relative;
  display: flex;
  margin: 0.5rem;
  flex-direction: column;
  padding-left: 35px;
  min-width: 250px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  color: #293040;
`;

const LegendColor = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  background-color: ${(props) => props.sqcolor};
  left: 7px;
  top: 8px;
`;

const AccountInfo = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  div:first-of-type {
    font-size: 30px;
    font-weight: 700;
    line-height: 1em;
    color: #367FFF;
  }
  div:nth-child(2) {
    font-size: 22px;
    margin-right: 0.5rem;
    color: #367FFF;
  }
`;

const LegendValue = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 0.2rem;
`;

const MouseDetectionArea = styled.rect`
  stroke: s#f00;
  fill: transparent;
`;

const AxisText = styled.text`
  font-size: 14px;
  fill: ${({ theme }) => theme.black};
`;
const StaticAxisText = styled(AxisText)`
  font-size: 14px;
  fill: ${({ theme }) => theme.secondary};
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

const SliderAndLegendContainer = styled.div`
  max-width: 90%;
  margin: auto;
  z-index: -1;
`;

const InitialFundsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
  div:first-of-type {
    color: ${({ theme }) => theme.grey};
    font-size: 16px;
  }
  div:last-child {
    color: ${({ theme }) => theme.black};
    font-size: 1.7rem;
    font-weight: 500;
  }
`;

const SlideInput = styled.input`
  position: relative;
  display: block;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  margin: auto;
  height: 2px; /* Specified height */
  background: #e6e6e6; /* Grey background */
  outline: none; /* Remove outline */
  margin-bottom: 2rem;
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 16px; /* Set a specific slider handle width */
    height: 16px; /* Slider handle height */
    border-radius: 16px;
    background: #fff; /* Green background */
    border: 1px solid #eee;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.35);
    cursor: pointer; /* Cursor on hover */
  }
  :-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }
`;

export {
  ChartSVG,
  LegendColor,
  LegendBlock,
  LegendContainer,
  AccountInfo,
  LegendValue,
  MouseDetectionArea,
  AxisText,
  StaticAxisText,
  InitialFundsContainer,
  SlideInput,
  SliderAndLegendContainer,
};
