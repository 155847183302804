import React, { useEffect, useContext } from 'react';
import { useIntl } from 'context/IntlContext';
import { useLocation } from 'context/LocationContext';
import ViewerContext from 'context/ViewerContext';

import Tabs from '../../../components/MegaTabs';
import Tab from '../../../components/MegaTabs/Tab';

import productsJSON from '../../../assets/products.json';
import TabContent from './TabContent';
import { useState } from 'react';

interface Product {
  id: string;
  titleKey: string;
  countryRestrictions: string[];
  localeRestrictions: string[];
  hidden: boolean;
}

const MarketplaceTabs = () => {
  const { t, locale } = useIntl();
  const { location } = useLocation();
  const { countryCode } = useContext(ViewerContext);

  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    setProducts(
      productsJSON.filter((product: Product) => {
        return (
          !product.countryRestrictions.includes(countryCode) &&
          !product.localeRestrictions.includes(locale) &&
          !product.hidden
        );
      }),
    );
  }, [countryCode, locale]);

  return (
    <Tabs activeTab={location?.hash}>
      {products.map(({ id, titleKey }: Product) => {
        return (
          <Tab key={id} hash={`#${id}`} id={id} title={t(titleKey)}>
            {<TabContent content={id} />}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default MarketplaceTabs;
